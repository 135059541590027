import ApplicationController from "./application_controller"
import Chart from "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"

export default class extends ApplicationController {
  static values = {
    labels: String,
    chartData: String,
    dateCount: Number,
    teamAndAvgCount: Number,
    showLabelInChart: Boolean,
    max: { type: Number, default: null },
    min: { type: Number, default: null },
    q1: { type: Number, default: null },
    median: { type: Number, default: null },
    q3: { type: Number, default: null },
    colorMode: { type: String, default: "gradient" }
  }

  connect() {
    super.connect()
    this._initChart = this._initChart.bind(this)
    if (typeof Chart !== "undefined" && Chart !== null) {
      this._initChart()
    } else {
      document.addEventListener("turbo:load", this._initChart, false)
    }
  }

  disconnect() {
    document.removeEventListener("turbo:load", this._initChart)
    this._chart.destroy()
    super.disconnect()
  }

  _initChart() {
    const matrixContext = this.element.querySelector("canvas").getContext("2d")
    const chartDates = this.labels
    const chartData = this.chartData
    const showLabelInChart = this.showLabelInChart
    const max = this.max
    const min = this.min
    const q1 = this.q1
    const median = this.median
    const q3 = this.q3
    const colorMode = this.colorMode
    const quartiles = [q1, median, q3, max]

    // Define color variables
    const tomatoRed = "rgba(255, 99, 71, 0.8)"
    const mediumSeaGreen = "rgba(60, 179, 113, 0.8)"
    const lightBorder = "rgba(0, 0, 0, 0.3)"
    const darkGrey = "rgba(0, 0, 0, 0.7)"
    const lightGrey = "rgba(236, 235, 241, 1)"

    const matrixData = {
      label: "My Matrix",
      datasets: [{
        data: chartData,
        backgroundColor(context) {
          const value = context.dataset.data[context.dataIndex].v
          const alphamin = (min !== null) ? min : context.dataset.data[context.dataIndex].min
          const alphamax = (max !== null) ? max : context.dataset.data[context.dataIndex].max
          const includeInColoring = context.dataset.data[context.dataIndex].include_in_coloring

          if (!includeInColoring) return lightGrey // Light grey for excluded data
          
          if (colorMode === "quartile-gradient") {
            let percentileIndex = 0;
            for (let i = 0; i < quartiles.length; i++) {
              percentileIndex = i;
              if (value < quartiles[i]) {
                break;
              }
            }
            const alpha = percentileIndex / 3
            return `rgba(0, 128, 0, ${alpha})`
          }  else if (colorMode === "threeColor") {
            if (value <= alphamin) return tomatoRed
            if (value >= alphamax) return mediumSeaGreen
            return lightGrey
          } else if (colorMode === "two-color-gradient") {
            const alpha = (value - alphamin) / (alphamax - alphamin)
            const q1 = 0.25
            const q3 = 0.75
            if (alpha < q1) {
              const redAlpha = (q1 - alpha) / q1
              return `rgba(128, 0, 0, ${redAlpha})`
            } else if (alpha > q3) {
              const greenAlpha = (alpha - q3) / (1 - q3)
              return `rgba(0, 128, 0, ${greenAlpha})`
            } else {
              return lightGrey // Grey for IQR
            }
          } else {
            let alpha = (value - alphamin) / (alphamax - alphamin)
            alpha = Math.max(alpha, 0)
            return `rgba(0, 128, 0, ${alpha})` // green gradient
          }
        },
        borderColor(context) {
          const value = context.dataset.data[context.dataIndex].v
          const alphamin = (min !== null) ? min : context.dataset.data[context.dataIndex].min
          const alphamax = (max !== null) ? max : context.dataset.data[context.dataIndex].max
          const includeInColoring = context.dataset.data[context.dataIndex].include_in_coloring
          
          if (!includeInColoring) return lightBorder // Light border for excluded data
          
          if (colorMode === "threeColor") {
            return lightBorder // Lighter border for three-color mode
          } else {
            let alpha = (value - alphamin) / (alphamax - alphamin)
            alpha = (Math.max(alpha, 0) + 0.1) * 0.8
            return `rgba(0, 0, 0, ${alpha})` // black gradient
          }
        },
        borderWidth: 1,
        width: ({ chart }) => (chart.chartArea || {}).width / this.dateCount - 1,
        height: ({ chart }) => (chart.chartArea || {}).height / this.teamAndAvgCount - 1
      }]
    }

    const matrixConfig = {
      type: "matrix",
      data: matrixData,
      plugins: [ChartDataLabels],
      options: {
        onClick: (e) => {
            const chart = this._chart
            const points = chart.getElementsAtEventForMode(e, "nearest", { intersect: true }, true)
            if (points.length) {
                const firstPoint = points[0]
                const label = this._chartData[firstPoint.index].y
                const queryParams = this._chartData[firstPoint.index].query_params
                const url = new URL(window.location.origin + "/activity")
                const id = new URLSearchParams(window.location.search).get("id")
                if (id) {
                  url.searchParams.append("id", id)
                }
                Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]))
                const canvas = this.element.querySelector("canvas")
                canvas.style.display = "none"
                canvas.parentElement.classList.add("loading")
                window.location.href = url.toString()
            } else {
              console.warn("No points clicked")
            }
        },
        onHover: (e) => {
          const chart = this._chart
          const points = chart.getElementsAtEventForMode(e, "nearest", { intersect: true }, true)
          e.native.target.style.cursor = points.length ? "pointer" : "default"
        },
        animation: false,
        plugins: {
          legend: false,
          tooltip: {
            displayColors: false,
            callbacks: {
              label(context) {
                const v = context.dataset.data[context.dataIndex]
                return [v.y + ": " + v.v]
              }
            }
          },
          datalabels: {
            color: (context) => {
              const value = context.dataset.data[context.dataIndex].v
              const alphamin = (min !== null) ? min : context.dataset.data[context.dataIndex].min
              const alphamax = (max !== null) ? max : context.dataset.data[context.dataIndex].max
              const includeInColoring = context.dataset.data[context.dataIndex].include_in_coloring
              
              if (!includeInColoring) return darkGrey // Dark grey for excluded data
              
              if (colorMode === "threeColor") {
                return darkGrey // Dark grey for better readability
              } else if (colorMode === "two-color-gradient") {
                let alpha = (value - alphamin) / (alphamax - alphamin)
                if (alpha > 0.85 || alpha < 0.1) {
                  return "white"
                } else {
                  return darkGrey
                }
              } else if (colorMode === "quartile-gradient") {
                if (value < q3) {
                  return "black"
                } else {
                  return "white"
                }
              } else {
                let alpha = (value - alphamin) / (alphamax - alphamin)
                return alpha < 0.75 ? "black" : "white"
              }
            },
            formatter(value) {
              return showLabelInChart ? value.v : ""
            }
          }
        },
        scales: {
          x: {
            type: "category",
            labels: chartDates,
            ticks: {
              display: true
            },
            grid: {
              display: false
            }
          },
          y: {
            type: "category",
            offset: true,
            ticks: {
              display: true
            },
            grid: {
              display: false
            }
          }
        }
      }
    }

    this._chart = new Chart(matrixContext, matrixConfig)
    const loadingDiv = this.element.querySelector(".loading")
    if(loadingDiv) {
      loadingDiv.classList.remove("loading")
    }
  }

  get chart() {
    return this._chart
  }
  get labels() {
    return this._labels = this._labels || JSON.parse(this.labelsValue)
  }
  get chartData() {
    return this._chartData = this._chartData || JSON.parse(this.chartDataValue)
  }
  get dateCount() {
    return this._dateCount = this._dateCount || JSON.parse(this.dateCountValue)
  }
  get teamAndAvgCount() {
    return this._teamAndAvgCount = this._teamAndAvgCount || JSON.parse(this.teamAndAvgCountValue)
  }
  get showLabelInChart() {
    return this._showLabelInChart = this._showLabelInChart || this.showLabelInChartValue
  }
  get max() {
    return this._max = this._max || this.maxValue || null
  }
  get min() {
    return this._min = this._min || this.minValue || null
  }
  get q1() {
    return this._q1 = this._q1 || this.q1Value || null
  }
  get median() {
    return this._median = this._median || this.medianValue || null
  }
  get q3() {
    return this._q3 = this._q3 || this.q3Value || null
  }
  get colorMode() {
    return this._colorMode = this._colorMode || this.colorModeValue
  }
}
